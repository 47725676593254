import classnames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const COPY = {
  en: {
    home: 'Home',
    about: 'About',
    work: 'Work',
    exhibitions: 'Exhibitions',
    publications: 'Publications',
    contact: 'Contact'
  },
  'pt-BR': {
    home: 'Início',
    about: 'Sobre',
    work: 'Trabalhos',
    exhibitions: 'Exposições',
    publications: 'Publicações',
    contact: 'Contato'
  }
};

const SLUGS = {
  en: {
    about: 'about',
    work: 'work',
    exhibitions: 'exhibitions',
    publications: 'publications',
    contact: 'contact'
  },
  'pt-BR': {
    about: 'sobre',
    work: 'trabalhos',
    exhibitions: 'exposicoes',
    publications: 'publicacoes',
    contact: 'contato'
  }
};

const Header = ({ locale = 'en', siteTitle = '' }) => {
  const [checked, setChecked] = useState(false);

  const baseHref = `/${locale === 'pt-BR' ? 'pt-BR/' : ''}`;
  const homeHref = locale === 'pt-BR' ? '/pt-BR' : '/';

  return (
    <header
      className={classnames('header', {
        checked: checked
      })}
    >
      <div>
        <h1 style={{ margin: 0 }}>
          <Link
            to={homeHref}
            style={{
              color: `#fafafa`,
              textDecoration: `none`
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
      <div className="menu-toggler">
        <input
          aria-hidden="true"
          type="checkbox"
          onChange={() => setChecked(!checked)}
        />

        <span />
        <span />
        <span />
      </div>
      <div className="menu">
        <div className="menu__items">
          <Link activeClassName="active" to={homeHref}>
            {COPY[locale].home}
          </Link>
          <Link
            activeClassName="active"
            to={`${baseHref}${SLUGS[locale].about}`}
          >
            {COPY[locale].about}
          </Link>
          <Link
            activeClassName="active"
            to={`${baseHref}${SLUGS[locale].work}`}
          >
            {COPY[locale].work}
          </Link>
          <Link
            activeClassName="active"
            to={`${baseHref}${SLUGS[locale].exhibitions}`}
          >
            {COPY[locale].exhibitions}
          </Link>
          <Link
            activeClassName="active"
            to={`${baseHref}${SLUGS[locale].contact}`}
          >
            {COPY[locale].contact}
          </Link>
        </div>
        <div className="languages">
          <Link
            to="/"
            className={classnames({
              active: locale !== 'pt-BR'
            })}
          >
            EN
          </Link>
          <Link
            to="/pt-BR"
            className={classnames({
              active: locale === 'pt-BR'
            })}
          >
            PT
          </Link>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  locale: PropTypes.string,
  siteTitle: PropTypes.string
};

export default Header;
